import create from 'zustand'
import { persist } from 'zustand/middleware'

type TContentSearchOnboardingState = {
  isFinished?: boolean
  setFinished: () => void
}
export const useContentSearchOnboarding = create<TContentSearchOnboardingState>(
  persist(
    (set) => ({
      isFinished: false,
      setFinished: () =>
        set(() => ({
          isFinished: true,
        })),
    }),
    {
      name: 'CONTENT_SEARCH_ONBOARDING_STORAGE_KEY',
    }
  )
)

type TContentSearchState = {
  errorType?: string
  setErrorType: (errorType: string) => void
}
export const useContentSearch = create<TContentSearchState>((set) => ({
  errorType: '',
  setErrorType: (errorType) =>
    set(() => ({
      errorType,
    })),
}))

type TContentSearchFilterOnboardingState = {
  isFinished?: boolean
  isCoachMarkFinished?: boolean
  setFinished: () => void
  setCoachMarkFinished: () => void
}
export const useContentSearchFilterOnboarding =
  create<TContentSearchFilterOnboardingState>(
    persist(
      (set) => ({
        isFinished: false,
        isCoachMarkFinished: false,
        setFinished: () =>
          set(() => ({
            isFinished: true,
          })),
        setCoachMarkFinished: () =>
          set(() => ({
            isCoachMarkFinished: true,
          })),
      }),
      {
        name: 'CONTENT_SEARCH_FILTER_ONBOARDING_STORAGE_KEY',
      }
    )
  )

type TContentSearchFilterState = {
  isSheetOpen?: boolean
  isShowQuickFilters?: boolean
  selectedFormats?: string[]
  selectedTopics?: string[]
  setSheetOpen: (value: boolean) => void
  setShowQuickFilters: (value: boolean) => void
  setSelectedFormat: (value: string) => void
  setSelectedFilters: (formats: string[], topics: string[]) => void
  checkSelectedFormatStatus: (value: string) => boolean
  hasAnyFilterSelected: () => boolean
  getAllSelectedFilters: () => string[]
}
export const useContentSearchFilter = create<TContentSearchFilterState>(
  (set, get) => ({
    isSheetOpen: false,
    isShowQuickFilters: false,
    selectedFormats: [],
    selectedTopics: [],
    setSheetOpen: (isSheetOpen: boolean): void =>
      set(() => ({
        isSheetOpen,
      })),
    setShowQuickFilters: (isShowQuickFilters: boolean): void =>
      set(() => ({
        isShowQuickFilters,
      })),
    setSelectedFormat: (selectedFormat: string): void =>
      set((state) => ({
        selectedFormats: state.selectedFormats.includes(selectedFormat)
          ? state.selectedFormats.filter((item) => item !== selectedFormat)
          : [...state.selectedFormats, selectedFormat],
      })),
    setSelectedFilters: (
      selectedFormats: string[],
      selectedTopics: string[]
    ): void =>
      set(() => ({
        selectedFormats,
        selectedTopics,
      })),
    checkSelectedFormatStatus: (selectedFormat: string): boolean => {
      const selectedFormats = get()?.selectedFormats ?? []
      return selectedFormats.includes(selectedFormat)
    },
    hasAnyFilterSelected: (): boolean => {
      const selectedFormats = get()?.selectedFormats ?? []
      const selectedTopics = get()?.selectedTopics ?? []
      return selectedFormats.length > 0 || selectedTopics.length > 0
    },
    getAllSelectedFilters: (): string[] => {
      return [
        ...(get()?.selectedFormats ?? []),
        ...(get()?.selectedTopics ?? []),
      ]
    },
  })
)

type TContentSearchTrackerState = {
  source?: string
  eventTrigger?: string
  setSource: (source: string) => void
  setEventTrigger: (eventTrigger: string) => void
}
export const useContentSearchTracker = create<TContentSearchTrackerState>(
  persist(
    (set) => ({
      source: '',
      eventTrigger: '',
      setSource: (source) =>
        set(() => ({
          source,
        })),
      setEventTrigger: (eventTrigger) =>
        set(() => ({
          eventTrigger,
        })),
    }),
    {
      name: 'CONTENT_SEARCH_TRACKER_STORAGE_KEY',
    }
  )
)
