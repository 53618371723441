import Head from 'next/head'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { Avatar } from '@wartek-id/avatar'
import { Text } from '@wartek-id/text'

import { useSETrackEvent } from 'app/Homepage/hooks/useSETrackEvent'
import { getUserDisplayName } from 'app/Homepage/utils/user'
import { useAuth } from 'utils/auth'
import {
  Header as HeaderBar,
  HeaderTitle,
  HeaderLeftAction,
  HeaderRightAction,
} from 'components/Header/HeaderComposition'

interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  isRuangGtkEnabled?: boolean
  title?: string
  showUserProfile?: boolean
  useHeaderV2?: boolean
}

const Header = ({
  isRuangGtkEnabled = false,
  title = '',
  showUserProfile = true,
  useHeaderV2,
  ...props
}: HeaderProps) => {
  const { session } = useAuth()
  const router = useRouter()
  const userDisplayName = getUserDisplayName(session?.user?.name)

  const { SETrackClickProfileEntryPoint } = useSETrackEvent()

  const onClickSetting = () => {
    SETrackClickProfileEntryPoint('CLICK_PROFILE_PICTURE')

    router.push('/akun')
  }

  const headerBgColor = isRuangGtkEnabled ? 'default' : 'milBlue'
  const headerTextColor = isRuangGtkEnabled ? 'default' : 'inverse'
  const headerLogo = isRuangGtkEnabled
    ? '/icon/ruang-gtk/logo-xs.svg'
    : '/images/logo-white.svg'

  return (
    <>
      <Head>
        <meta
          name="theme-color"
          media="(prefers-color-scheme: light)"
          content="#103178"
        />
        <meta
          name="theme-color"
          media="(prefers-color-scheme: dark)"
          content="#103178"
        />
      </Head>

      <HeaderBar
        className="!justify-center"
        hasBorder={false}
        backgroundColor={headerBgColor}
        {...props}
      >
        <HeaderLeftAction>
          <button onClick={() => router.push('/home')}>
            <Image
              src={headerLogo}
              alt="Logo"
              width={isRuangGtkEnabled ? 65 : 32}
              height={isRuangGtkEnabled ? 36 : 32}
            />
          </button>
        </HeaderLeftAction>
        <HeaderTitle>
          {useHeaderV2 ? (
            <div className="absolute mr-3 break-all left-16 line-clamp-1">
              <Text variant="heading-sm" color={headerTextColor}>
                Halo, {userDisplayName}
              </Text>
            </div>
          ) : (
            <div className="flex flex-col items-center">
              <Text variant="heading-sm" color={headerTextColor}>
                {title}
              </Text>
            </div>
          )}
        </HeaderTitle>
        {showUserProfile && (
          <HeaderRightAction>
            <button
              data-testid="header-profile-picture"
              onClick={onClickSetting}
            >
              <Avatar
                src={session.user.picture}
                name={session.user.name}
                size="md"
                alt="User Picture"
              />
            </button>
          </HeaderRightAction>
        )}
      </HeaderBar>
    </>
  )
}

export default Header
