import clsx from 'clsx'
import React from 'react'
import { useRouter } from 'next/router'

import { Avatar } from '@wartek-id/avatar'

import { BottomNav } from 'components/BottomNav'
import { useAuth } from 'utils/auth'
import { useSETrackEvent } from 'app/Homepage/hooks/useSETrackEvent'
import {
  useContentSearchOnboarding,
  useContentSearchTracker,
} from 'stores/content-search'
import GtkHomeIcon from 'public/icon/ruang-gtk/nav-beranda.svg'
import GtkSearchIcon from 'public/icon/ruang-gtk/nav-cari.svg'
import GtkInfoIcon from 'public/icon/ruang-gtk/nav-pemberitahuan.svg'

import type { NavItem } from 'components/BottomNav'

import HomeFilledIcon from 'assets/icons/home-filled.svg'
import InfoTerkiniIcon from 'assets/icons/info-terkini.svg'
import SearchIcon from 'assets/icons/search-alt.svg'

const ConfigIconWithAvatar = () => {
  const { session } = useAuth()
  const { asPath } = useRouter()

  return (
    <Avatar
      src={session?.user.picture}
      name={session?.user.name}
      size="sm"
      alt="Avatar"
      className={clsx(
        '!w-[24px] !h-[24px] border',
        asPath === '/akun' ? 'border-[#4C8DF6]' : 'border-[#808489]'
      )}
    />
  )
}

const NavigationBar = ({ isRuangGtkEnabled = false }) => {
  const { SETrackClickBottomNavbar, SETrackClickInformationPage } =
    useSETrackEvent()
  const contentSearchOnboardingStore = useContentSearchOnboarding()
  const contentSearchTrackerStore = useContentSearchTracker()

  const navItems: NavItem[] = React.useMemo(
    () => [
      {
        href: '/home',
        icon: isRuangGtkEnabled ? GtkHomeIcon : HomeFilledIcon,
        text: 'Beranda',
      },
      {
        href: '/pencarian',
        icon: isRuangGtkEnabled ? GtkSearchIcon : SearchIcon,
        text: 'Cari',
      },
      {
        href: '/info-terkini',
        icon: isRuangGtkEnabled ? GtkInfoIcon : InfoTerkiniIcon,
        text: isRuangGtkEnabled ? 'Pemberitahuan' : 'Info Terkini',
        // disable badge for now
        // hasBadge: true,
      },
      {
        href: '/akun',
        text: 'Akun',
        icon: ConfigIconWithAvatar,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const onNavigate = (href: string): void => {
    const paths = {
      '/home': 'CLICK_NAVBAR_HOMEPAGE',
      '/ide-praktik': 'CLICK_NAVBAR_IDE_PRAKTIK',
      '/learning-group': 'CLICK_NAVBAR_CLASS',
      '/pencarian': 'CLICK_NAVBAR_SEARCH_PAGE',
      '/akun': 'CLICK_PROFILE_PICTURE_TAB',
      '/info-terkini': 'CLICK_NAVBAR_INFORMATION_PAGE',
    }

    if (paths[href]) {
      SETrackClickBottomNavbar(paths[href])
    }

    if (href == '/info-terkini') {
      SETrackClickInformationPage('OPEN_INFORMATION_PAGE')
    }

    if (href == '/pencarian') {
      contentSearchTrackerStore.setSource('NAVBAR_SEARCH_PAGE')

      if (!contentSearchOnboardingStore.isFinished) {
        contentSearchOnboardingStore.setFinished()
      }
    }
  }

  return (
    <BottomNav
      isRuangGtkEnabled={isRuangGtkEnabled}
      navItems={navItems}
      data-testid="home-navigation-bar"
      onNavigate={onNavigate}
    />
  )
}

export default NavigationBar
