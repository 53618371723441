export const getUserDisplayName = (name: string): string => {
  if (typeof name !== 'string') {
    return ''
  }

  const nameArr = name.split(' ')

  if (nameArr[0].length > 3) {
    return nameArr[0]
  }

  if (nameArr[0].length <= 3 && Boolean(nameArr?.[1])) {
    return `${nameArr[0]} ${nameArr[1]}`
  }

  return name
}
